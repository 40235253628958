.hero-item .image {
  position: relative;
}
.hero-item .image .video {
  position: relative;
}
.hero-item .image .video .video-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(17, 24, 32, 0.4);
  color: #f4f4f4;
  padding: 0.3rem 1.5rem;
}
.hero-item img {
  width: 100%;
  height: auto;
}
.hero-item .title a {
  text-decoration: none;
}
.hero-item .title h2 {
  color: #011d42;
}
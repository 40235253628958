.spinner {
  width: 100%;
  text-align: center;
  font-size: 48px;
}
.spinner ion-icon {
  animation: spin 2s infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.df-week {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #cccccc;
  margin-bottom: 3px;
  padding: 3px 0;
}
.df-day {
  min-width: 30px;
  padding: 3px;
  margin: 0 3px;
  background-color: #ffffff;
  cursor: pointer;
  text-align: center;
  border-radius: 6px;
}
.df-day.selected {
  background-color: #ff8888;
}
.reset-dates {
  cursor: pointer;
  font-size: 0.8em;
}
.df-day:hover {
  background-color: #cccccc;
}
.df-day.selected:hover {
  background-color: #ffcccc;
}
.search-box {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffd041;
  height: 100%;
  display: flex;
  align-items: center;
  width: 30%;
}
.search-box form {
  position: relative;
  padding: 0 1rem;
  width: 100%;
}
.search-box form input {
  border-radius: 0;
  border: 1px solid #626167;
  font-size: 1rem;
  outline: none;
}
.search-box form input.form-control {
  padding: 0.4rem;
  height: auto;
  outline: none;
}
.search-box form input::placeholder {
  font-style: italic;
}
.search-box form .btn-primary {
  background-color: transparent;
  border: none;
  color: #011d42;
  padding: 0;
  line-height: auto;
  font-size: 2rem;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}
// Sizing
$max-line-length : 1200px;
$half-line-length : calc($max-line-length / 2);

// Dark Theater Blue
$color-dark-theater-blue: #011d42;
$color-dark-theater-blue-85: #233355;
$color-dark-theater-blue-70: #3f4a6a;
$color-dark-theater-blue-55: #5c6482;
$color-dark-theater-blue-40: #7e849d;
$color-dark-theater-blue-25: #a5a9bc;
$color-dark-theater-blue-10: #d4d5df;

// Movie Ticket Blue
$color-movie-ticket-blue: #0075c9;
$color-movie-ticket-blue-85: #3281be;
$color-movie-ticket-blue-70: #5b90c7;
$color-movie-ticket-blue-55: #7ca2d0;
$color-movie-ticket-blue-40: #9cb7db;
$color-movie-ticket-blue-25: #becee7;
$color-movie-ticket-blue-10: #e2e8f4;

// Butter Yellow
$color-butter-yellow: #ffd041;
$color-butter-yellow-85: #f8d876;
$color-butter-yellow-70: #f9de8d;
$color-butter-yellow-55: #fbe4a3;
$color-butter-yellow-40: #fceaba;
$color-butter-yellow-25: #fcf0d1;
$color-butter-yellow-10: #fef9ea;

// Theater Seat Red
$color-theater-seat-red: #b2282e;
$color-theater-seat-red-85: #ae5246;
$color-theater-seat-red-70: #b86c5c;
$color-theater-seat-red-55: #c38675;
$color-theater-seat-red-40: #d0a192;
$color-theater-seat-red-25: #dec0b4;
$color-theater-seat-red-10: #efe2db;

// Candy Green
$color-candy-green: #2d9a47;

// Popcorn Orange
$color-popcorn-orange: #ff8400;

// Screen Gray
$color-screen-gray: #aca097;

// Black and White
$black: #111820;
$black-85: #2d2f36;
$black-70: #46474c;
$black-55: #626167;
$black-40: #838287;
$black-25: #a9a8ab;
$black-10: #d6d5d6;

$white: #f4f4f4;

// Other Colors
$link: $color-movie-ticket-blue;


$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1200px
);

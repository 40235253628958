@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;600&display=swap");
.text.paragraph p,
.text.paragraph ul,
.text.paragraph ol,
.text.paragraph table {
  max-width: 960px;
  margin: 0 auto 1em;
}
.text.paragraph p:last-of-type {
  margin-bottom: 0;
}
.text.paragraph h1,
.text.paragraph h2,
.text.paragraph h3,
.text.paragraph h4,
.text.paragraph h5,
.text.paragraph h6 {
  max-width: 960px;
  margin: auto;
}
.text.paragraph img {
  max-width: 100%;
  width: auto;
}
.text.paragraph .button {
  margin-top: 1rem;
}
.text.paragraph blockquote {
  padding: 20px;
  padding-left: 60px;
  border-top: 2px solid #0075c9;
  border-bottom: 2px solid #0075c9;
  max-width: 840px;
  margin: 0 auto 1em;
}
.text.paragraph blockquote p {
  font-weight: 600;
}
.text.paragraph blockquote p:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .text.paragraph blockquote.float {
    width: 40%;
    margin: 10px 0 20px 20px;
    float: right;
  }
}
.text.paragraph blockquote:before {
  content: url("/src/images/svg/icon_blockquote.svg");
  position: absolute;
  margin-left: -40px;
  height: 24px;
  width: 24px;
}
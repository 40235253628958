.item-card {
  margin-bottom: 2rem;
}
.item-card .image {
  margin-bottom: 0.75rem;
}
.item-card .title {
  margin-bottom: 0.3rem;
}
.item-card .title a {
  text-decoration: none;
  color: #111820;
}
.item-card .body .text {
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.item-card .link {
  margin-top: 0.7rem;
}
.film-mini h4 {
  border-bottom: 1px solid #011d42;
  margin-bottom: 0.5rem;
}
.film-mini .image {
  padding-right: 1.5rem;
  width: 33%;
}
.film-mini .link {
  text-align: right;
  margin-top: 1rem;
}
.placeholder {
  background-image: linear-gradient(to right, #a9a8ab, #a9a8ab, #d6d5d6, #a9a8ab, #a9a8ab);
  background-position: left;
  background-size: 300%;
  animation: placeholder-animation 5s infinite;
  display: block;
}
.placeholder.box {
  background-color: transparent;
  border: 1px solid #a9a8ab;
  padding: 1.5rem;
}
.placeholder .text-line {
  width: 100%;
  height: 24px;
  margin-bottom: 0.5rem;
}
.placeholder .text-line.h1 {
  height: 54px;
}
.placeholder .text-line.h2 {
  height: 44px;
}
.placeholder .text-line.h3 {
  height: 36px;
}
.placeholder .text-line.h4 {
  height: 28px;
}
.placeholder .text-line.h5 {
  height: 22px;
}
.placeholder .text-line:nth-child(2) {
  width: 78%;
}
.placeholder .text-line:nth-child(3) {
  width: 89%;
}
.placeholder .text-line:nth-child(4) {
  width: 46%;
}
.placeholder .image-video {
  width: 100%;
  aspect-ratio: 2.39;
}
.placeholder .button-placeholder {
  height: 48px;
}

@keyframes placeholder-animation {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
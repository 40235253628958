.modal-overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(244, 244, 244, 0.95);
  z-index: 4;
  overflow: auto;
}
.modal-overlay .modal-close-trigger {
  left: 0;
  top: 0;
  line-height: 1;
  position: absolute;
  padding: 1rem;
  font-size: 3rem;
}
.modal-overlay .modal-close-trigger:hover {
  cursor: pointer;
}
.modal-overlay .modal-overlay-content {
  max-width: 80vw;
  transform: translateX(-50%);
  height: 100vh;
  position: absolute;
  left: 50%;
  top: 70px;
  object-fit: scale-down;
}
.modal-overlay .modal-overlay-content img {
  object-fit: scale-down;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}
.modal-overlay .modal-overlay-content.menu {
  width: 100%;
  transform: none;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  padding-top: 3rem;
}
.modal-overlay .modal-overlay-content.video {
  max-height: 90vh;
  width: 100%;
}
.modal-overlay .modal-overlay-content.video .remote-video {
  height: 100%;
  object-fit: contain;
  padding: 0;
}
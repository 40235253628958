.accordion {
  margin-top: 0.5em;
}
.accordion .accordion-content {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s, height 0.5s linear;
  margin-top: 0.25rem;
}
.accordion .accordion-content.collapsed {
  visibility: hidden;
  opacity: 0;
  height: 0;
}
.accordion .accordion-content.collapsed * {
  height: 0;
}
.button-wrapper .button.buy-button {
  text-decoration: none;
  padding: 0.6rem 2rem;
}
.button-wrapper .button.buy-button.grey-color {
  background-color: #d6d5d6;
  color: #626167;
}
.button-wrapper .button.buy-button.active-color {
  color: #111820;
  background-color: #ffd041;
}
.button-wrapper .button.buy-button.disabled {
  cursor: inherit;
  background-color: #d6d5d6;
  color: #626167;
}
.button-wrapper .button.buy-button.cancelled {
  word-wrap: normal;
}
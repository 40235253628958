.offering-card {
  margin-bottom: 2rem;
  background-color: #f4f4f4;
  border: 1px solid #626167;
}
.offering-card h3 a {
  text-decoration: none;
}
.offering-card .film-info {
  border-right: 1px solid #626167;
}
.offering-card .film-info .details {
  padding: 1.5rem;
}
.offering-card .image {
  width: 100%;
}
.offering-card .image img {
  width: 100%;
}
.offering-card .sidebar-item {
  border: none;
  border-bottom: 1px solid #626167;
}
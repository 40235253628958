.sidebar-item {
  background-color: #f4f4f4;
  padding: 1em;
  border: 1px solid #626167;
  border-bottom: none;
}
.sidebar-item:last-of-type {
  border-bottom: 1px solid #626167;
}
.sidebar-item .subtitle {
  font-weight: bold;
}
.sidebar-item .button-wrapper {
  margin: 0.7rem 0;
}
.sidebar-item .button-wrapper .button {
  display: block;
  width: 100%;
  text-align: center;
}
.sidebar-item.alternate {
  background-color: #becee7;
}
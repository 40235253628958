.selector .selector-wrapper {
  position: relative;
  background-color: #ffd041;
}
.selector .selector-wrapper .selector-header {
  display: flex;
  justify-content: center;
  padding: 0.7rem;
}
.selector .selector-wrapper .selector-header ion-icon {
  margin-left: 0.5em;
}
.selector .selector-wrapper .selector-header ion-icon:hover {
  cursor: pointer;
}
.selector .item-list-container {
  position: relative;
}
.selector .item-list-container .item-list {
  position: absolute;
  left: 0;
  min-height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  box-shadow: 0 0 15px rgba(17, 24, 32, 0.2);
  padding: 0.7rem;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}
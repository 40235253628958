@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;600&display=swap");
footer .pre-footer {
  background-color: #ffd041;
  padding: 1rem;
}
footer .pre-footer .container {
  width: 86vw;
}
footer .pre-footer .follow {
  text-align: center;
}
footer .pre-footer .follow a {
  font-size: 2rem;
  color: #011d42;
}
footer .pre-footer .follow h4 {
  text-align: center;
}
footer .page-footer {
  background-color: #011d42;
  color: #f4f4f4;
  padding: 1.5rem 0;
}
footer .page-footer .container {
  width: 86vw;
}
footer .page-footer h2, footer .page-footer h4, footer .page-footer a {
  color: #f4f4f4;
}
footer .page-footer h4 {
  border-bottom: 2px solid #f4f4f4;
}
footer .page-footer a {
  color: #011d42;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  color: #f4f4f4;
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
  padding: 0.2em 0;
}
footer .page-footer .menus {
  flex-direction: column;
}
@media (min-width: 800px) {
  footer .page-footer .menus {
    flex-direction: row;
  }
}
footer .page-footer .menu-wrapper {
  margin: 0 1rem;
}
footer .page-footer .menu-wrapper .menu:first-child .menu-branch:first-child {
  border-bottom: 2px solid #f4f4f4;
  margin-bottom: 0.2em;
}
footer .page-footer .menu-wrapper .menu:first-child .menu-branch:first-child a {
  color: #011d42;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #f4f4f4;
  padding-bottom: 0;
}
footer .post-footer {
  font-size: 0.9rem;
  color: #f4f4f4;
  margin-bottom: 1em;
}
footer .post-footer .ajc {
  background-color: #111820;
  padding: 1rem 0;
  margin-bottom: 1rem;
  line-height: 1.4em;
}
footer .post-footer .ajc .logo {
  margin-right: 1rem;
}
footer .post-footer .ajc .logo img {
  width: 150px;
}
footer .post-footer .container {
  width: 86vw;
}
footer .post-footer a {
  color: #f4f4f4;
}
footer .post-footer .signature {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  footer .post-footer .signature {
    margin-top: 0;
    text-align: right;
  }
}
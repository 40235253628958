.super-shopper-page.container {
  width: 86vw;
}
.super-shopper-page .filter-wrapper {
  background-color: rgba(0, 117, 201, 0.2);
  padding: 1.5rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .super-shopper-page .filter-wrapper .filter {
    max-width: 24%;
    width: 23%;
  }
}
.super-shopper-page .filter-wrapper select {
  max-width: 100%;
  width: 100%;
}
.super-shopper-page .filter-wrapper .clear-filters {
  text-decoration: underline;
  color: #011d42;
  margin-top: 1rem;
}
.super-shopper-page .filter-wrapper .filters {
  gap: 1em;
  margin: 1rem 0;
}
.super-shopper-page .controls {
  margin-bottom: 1rem;
  align-items: center;
}
.super-shopper-page .controls .sort-wrapper {
  text-align: right;
}
.super-shopper-page .controls .title-search-wrapper {
  position: relative;
}
.super-shopper-page .controls .title-search-wrapper input {
  padding: 0.2rem;
}
.super-shopper-page .controls .title-search-wrapper .icon {
  position: absolute;
  left: 195px;
  top: 10px;
}
.super-shopper-page select {
  padding: 0.5rem;
}
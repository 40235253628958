.spotlight {
  background-color: #0075c9;
  height: 100%;
  padding: 2rem;
}
.spotlight h4,
.spotlight p,
.spotlight a {
  color: #f4f4f4;
}
.spotlight h4 a {
  text-decoration: none;
}
.spotlight-list {
  background-color: #f4f4f4;
  padding: 2rem 0 0;
  width: 100%;
  margin-bottom: 1em;
}
.spotlight-list .row {
  max-width: 1200px;
  margin: auto;
}
.spotlight-list .row > div {
  margin-bottom: 2rem;
}
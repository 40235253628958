@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;600&display=swap");
header .page-header {
  margin-bottom: 1.5rem;
  background-color: #011d42;
}
header .page-header .page-header-content {
  color: #f4f4f4;
  position: relative;
  width: 86vw;
  max-width: 1200px;
  margin: auto;
}
header .page-header .branding {
  display: flex;
  align-items: flex-end;
  padding: 0.8rem 0;
}
header .page-header .branding img {
  width: 250px;
}
header .page-header .menu a {
  color: #011d42;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  color: #f4f4f4;
}
header .page-header .main-menu .menu {
  display: flex;
  justify-content: flex-end;
}
header .page-header .main-menu .menu a {
  padding: 0.5em 0.75em;
  text-decoration: none;
  font-size: 20px;
}
header .page-header .main-menu .menu .link.active a {
  border-bottom: 1px solid #f4f4f4;
}
header .page-header .page-header-content {
  align-items: center;
}
header .superheader {
  background-color: #0075c9;
  padding: 0.4rem 0;
}
header .superheader .superheader-content,
header .superheader a {
  color: #011d42;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  color: #f4f4f4;
  text-decoration: none;
  font-size: 16px;
  display: flex;
}
header .superheader .superheader-content {
  width: 86vw;
  max-width: 1200px;
  margin: auto;
  padding: 0.125rem 0;
}
header .superheader .menu {
  display: none;
}
@media (min-width: 768px) {
  header .superheader .menu {
    justify-content: flex-end;
  }
  header .superheader .menu .item {
    padding-left: 1em;
    line-height: 1.25;
  }
  header .superheader .menu .item ion-icon {
    line-height: 1;
    margin-right: 4px;
    font-size: 18px;
  }
}
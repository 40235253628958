@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;600&display=swap");
.button {
  color: #011d42;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  padding: 0.6rem 2rem;
  color: #111820;
  text-decoration: none;
  background-color: #ffd041;
  font-weight: 600;
  white-space: normal;
}
.button:hover {
  text-decoration: none;
  color: #0075c9;
  background-color: #ffd041;
}
.button.disabled {
  cursor: inherit;
  background-color: #d6d5d6;
  color: #626167;
}
.button.disabled:hover {
  background-color: #d6d5d6;
  color: #626167;
  cursor: default;
}
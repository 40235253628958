.item-list {
  padding-top: 0.7rem;
}
.item-list .headline {
  flex-grow: 1;
  color: #011d42;
}
.item-list .link {
  flex-shrink: 1;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .item-list .link {
    margin-bottom: 0;
  }
}
.item-list .button {
  font-weight: 600;
}
.modal-menu .modal-overlay {
  background-color: #011d42;
}
.modal-menu .modal-overlay .modal-overlay-content {
  margin-top: 0;
  transform: none;
  color: #f4f4f4;
  max-width: 100vw;
}
.modal-menu .modal-menu-trigger {
  font-size: 2rem;
  color: #f4f4f4;
}
.modal-menu .modal-close-trigger {
  color: #f4f4f4;
  z-index: 2;
}
.modal-menu .modal-menu-container {
  padding: 1rem 4rem 3rem;
  text-align: left;
  overflow: auto;
  height: 100vh;
}
.modal-menu .modal-menu-container .menu .link,
.modal-menu .modal-menu-container .menu .item {
  margin-bottom: 1rem;
}
.modal-menu .modal-menu-container .menu a {
  color: #f4f4f4;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.2rem;
  display: flex;
  gap: 0.5rem;
}
.modal-menu .modal-menu-container .menu a .icon {
  display: flex;
}
.modal-menu .modal-menu-container .menu .menu-branch .menu-branch {
  margin-left: 3rem;
}
.modal-menu .modal-menu-container .menu .menu-branch {
  position: relative;
}
.modal-menu .modal-menu-container .menu .menu-branch .expand-trigger {
  background-color: transparent;
  height: 2em;
  width: 2em;
  position: absolute;
  left: -1em;
  top: -0.5em;
  transform: translateY(0.85rem);
  font-size: 2em;
  cursor: pointer;
}
.modal-menu .modal-menu-container .menu .menu-branch .expand-trigger svg {
  color: #ffd041;
}
.modal-menu .modal-menu-container .menu .menu-branch .link .active-indicator {
  background-color: #ffd041;
  height: 8px;
  width: 36px;
  position: absolute;
  display: none;
  bottom: 8px;
  transform: translateY(0.85rem);
}
.modal-menu .modal-menu-container .menu .menu-branch .link.active .active-indicator {
  display: block;
}
.modal-menu .modal-menu-container .menu .menu-branch .link.active a {
  color: #ffd041;
}
.modal-menu .modal-menu-container .menu.secondary a {
  line-height: 1;
}
.modal-menu .modal-menu-container .search-box {
  position: relative;
  right: auto;
  top: auto;
  background-color: transparent;
  height: auto;
  display: block;
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #f4f4f4;
}
.modal-menu .modal-menu-container .search-box form {
  padding: 0;
}
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;600&display=swap");
.clock-background-wrapper {
  justify-content: space-between;
  padding: 1em;
  background-color: black;
  color: #f4f4f4;
  align-items: center;
  margin: 1em 0;
}
.clock-background-wrapper h2, .clock-background-wrapper span {
  color: #f4f4f4;
  margin-bottom: -0.1rem;
  color: #F4F4F4;
}

.clock {
  font-family: "Heebo", sans-serif;
  font-size: 2em;
  color: #f4f4f4;
  line-height: 1.3;
  display: flex;
  font-weight: 700;
}
.clock div.time-divider {
  padding: 0 7px;
}
.clock.tick div.time-divider {
  color: #0075c9;
}
.clock .clock-segment {
  display: flex;
  flex-direction: column;
}
.clock .segment-value {
  text-align: center;
}
.clock .segment-label {
  font-size: 16px;
  font-weight: 400;
}
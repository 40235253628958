@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;600&display=swap");
.accordion-list .accordion {
  margin: 1em 0;
  border-top: 1px solid #909090;
}
.accordion-list .accordion .trigger {
  color: #011d42;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  padding: 0.5em 0.2em;
  font-size: 20px;
}
.film-offering-detail-page > .container {
  width: 86vw;
}
.film-offering-detail-page .main-image {
  margin-bottom: 1rem;
  position: relative;
}
.film-offering-detail-page .main-image .trailer-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(17, 24, 32, 0.4);
  color: #f4f4f4;
  padding: 0.3rem 1.5rem;
}
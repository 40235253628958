.meta {
  margin-bottom: 1rem;
}
.meta .meta-label {
  margin-bottom: 0;
  font-weight: 600;
}
.meta .meta-label.inline {
  font-size: 22px;
}
.meta .inline {
  display: inline;
}
.meta .inline .meta-item {
  display: inline;
  padding-left: 0.5rem;
}
.meta.alternate {
  margin-bottom: 0;
}
.meta.alternate .meta-label {
  font-size: 18px;
  font-weight: 600;
}
@import '../../../../scss/common/settings';

.happening-card {
    margin-bottom: 2rem;
    background-color: $white;
    border: 1px solid $black-55;

    h3 {

        a {
            text-decoration: none;
        }
    }

    .image {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .sidebar-item {
        border: none;
        border-bottom: 1px solid $black-55;
    }
}